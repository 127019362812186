<template>
  <div>
    <eventsList />
    <eventsAdd v-if="!$store.state.privateEvent.unsaved.events.length" />
    <saveBtn
      v-if="checkData()"
      class="saveBtn"
      :callback="
        function() {
          setSave();
        }
      "
    />
  </div>
</template>
<script>
import def from "../default/";
export default {
  extends: def,
  components: {
    eventsList() {
      return import("./eventsList");
    },
    eventsAdd() {
      return import("./eventsAdd");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    nextStep() {
      this.$router.push({ name: "privateSettings" });
    },
    checkData() {
      var data = JSON.parse(
        JSON.stringify(this.$store.state.privateEvent.unsaved)
      );
      if (!data.events.length) {
        return false;
      }
      return true;
    }
  }
};
</script>
